<template>
	<div class="container flex f-column f-align">
		<span class="font40 bold black mb30">箱门列表</span>
		<div class="flex f-align">
			<div class="flex f-column f-align xm-left">
				<img src="../assets/image/up.png" @click="top -= 85" v-show="groupNum > 5">
				<div class="group-wai">
					<div class="group-nei" :style="{'top': top + 'px'}">
						<div @click="() => { groupIndex = item, getDataList() }" class="group-item" :class="{'group-item-s': item == groupIndex}" v-for="item in groupNum" :key="item">第{{item}}组</div>
					</div>
				</div> 
				<img src="../assets/image/down.png" @click="top += 85" v-show="groupNum > 5">
			</div>
			<div class="flex f-justifyC" style="width: 610px;">
				<div class="flex f-justifyB xm-center">
					<div class="flex" v-for="(item, index) in boxList" :key="index">
						<div class="flex f-column">
							<div class="xm-box" v-for="(list, i) in item.list" :key="i" @click="selxm(list)">
								<div class="xm-box-item" :class="{'gray-bg': list.status == '空闲', 'yellow-bg': list.status != '空闲', 'green-bg': list.choose }">{{list.xmbh}}</div>
							</div>
						</div>
						<div class="xm-line" v-if="(index+1) < boxList.length"></div>
					</div>
				</div>
			</div>
			<div class="flex f-column">
				<div class="flex f-align">
					<div class="gray-bg s-item"></div>
					<span class="font20">空箱门</span>
				</div>
				<div class="flex f-align">
					<div class="yellow-bg s-item"></div>
					<span class="font20">已占用</span>
				</div>
				<div class="flex f-align">
					<div class="green-bg s-item"></div>
					<span class="font20">已选中</span>
				</div>
			</div>
		</div>
		<div class="flex f-align f-justifyB font30 white">
			<div class="btn f-center" @click="openmenall">打开全部</div>
			<div class="btn f-center" @click="$router.replace('/')">返回</div>
			<div class="btn f-center" @click="openmen">打开箱门</div>
		</div>
	</div>
</template>
<script>
	import axios from 'axios'
	import qs from 'querystring'
	export default {
		data() {
			return {
				top: 0,
				boxList: [],
				groupNum: '', // 共有几组
				groupIndex: 1,
				openCmd: '',
				portName: '',
				xmbh: '',
				allList: []
			};
		},
		mounted() {
			this.$emit('change', '/adminList')
			this.getDataList()
		},
		methods: {
			openmenall() {
				const loading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
					target: '.app-box'
				});
				for (let i = 0; i < this.allList.length; i++) {
					setTimeout(() => {
						try{
							this.$post('/Box/open', {
								xmbh: this.allList[i].xmbh,
								gzbh: this.allList[i].gzbh,
								openMsg: '箱门列表开门',
								adminId: localStorage.getItem('adminId')
							})
						}catch(e){
							//TODO handle the exception
						}
						if (i == this.allList.length - 1) {
							loading.close()
						}
					}, 800 * i)
				}
			},
			selxm(item) {
				this.boxList.forEach(item => {
					item.list.forEach(a => a.choose = false)
				})
				item.choose = !item.choose
				this.portName = item.portName
				this.openCmd = item.openCmd
				this.xmbh = item.xmbh
				this.$forceUpdate()
			},
			getDataList() {
				this.$post('/Box/select', {
					gzbh: localStorage.getItem('gzbh')
				}).then((res) => {
					if (res.code == 200) {
						this.groupNum = res.number
						this.getXmList()
					} 
				})
			},
			getXmList() {
				this.$post('/Box/list', {
					gzbh: localStorage.getItem('gzbh'),
					plcAdd: this.groupIndex > 9 ? this.groupIndex : '0' + this.groupIndex
				}).then((res) => {
					if (res.code == 0) {
						this.allList = res.data
						res.data.forEach(item => item.choose = false)
						let count = res.count
						let col = Math.ceil(count / 8)
						let arr = []
						for (let i = 0; i < col; i++) {
							if (i == col - 1) {
								let yushu = count % 8
								if (!yushu) {
									arr.push({ list: res.data.slice(8 * i, 8 * (i + 1)) })
								} else {
									arr.push({ list: res.data.slice(8 * i, 8 * i + yushu) })
								}
							} else {
								arr.push({ list: res.data.slice(8 * i, 8 * (i + 1)) })
							}
						}
						this.boxList = arr
					} else {
						this.boxList = []
					}
				})
			},
			openmen() {
				if (this.openCmd && this.portName) {
					const loading = this.$loading({
						lock: true,
						text: 'Loading',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)',
						target: '.container'
					});
					try{
						this.$post('/Box/open', {
							xmbh: this.xmbh,
							gzbh: localStorage.getItem('gzbh'),
							openMsg: '箱门列表开门',
							adminId: localStorage.getItem('adminId')
						})
						loading.close()
					}catch(e){
						//TODO handle the exception
						loading.close()
					}
				} else {
					this.$toast('请选择箱门');
				}
			}
		}
	};
</script>
<style scoped lang="scss">
	.container {
		padding-top: 40px;
		
		.xm-left {
			margin-right: 15px;
			
			img{
				width: 40px;
				height: 40px;
			}
			
			.group-wai {
				width: 150px;
				height: 390px;
				overflow: hidden;
				position: relative;
				margin: 26px 0;
			}
			.group-nei {
				position: absolute;
				left: 0;
				top: 0;
				transition: top .5s;
			}
			
			.group-item {
				width: 150px;
				height: 50px;
				background: #C9C9C9;
				border-radius: 15px;
				color: #FFFFFF;
				font-size: 20px;
				line-height: 50px;
				text-align: center;
				margin-bottom: 35px;
			}
			
			.group-item-s {
				background: #4082FD;
			}
		}
		
		.xm-center {
			border: 2px solid #000000;
			padding: 6px 6px 20px;
			
			.xm-box {
				width: 160px;
				height: 57px;
				border: 2px solid #000000;
				margin-bottom: 3px;
				padding: 8px;
			}
			
			.xm-box-item {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 24px;
				/* color: #FFFFFF; */
			}
			
			.xm-line {
				width: 48px;
				height: 478px;
				border: 2px solid #000000;
				margin: 0 3px;
			}
		}
		
		.s-item {
			width: 24px;
			height: 24px;
			border-radius: 6px;
			margin: 53px 15px;
		}
		
		.yellow-bg {
			background: #F19A2D;
		}
		
		.gray-bg {
			background: #D5D5D5;
		}
		
		.green-bg {
			background: #24C2A1;
		}
		
		.btn {
			width: 200px;
			height: 80px;
			background: #4082FD;
			border-radius: 6px;
			margin: 30px 55px 0;
		}
	}
</style>
